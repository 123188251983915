Eloca.behaviors.produtos = function (context) {
    $(".js-tipo-produto", context).on("change", function () {
        if (this.value == 4) {
            $(".js-precos-fixos", context).removeClass("d-none");
            $(".js-selecao-faixas-preco", context).addClass("d-none");
            $(".js-faixas-precos", context).addClass("d-none");
        } else {
            $(".js-selecao-faixas-preco", context).removeClass("d-none");
        }
    });
    $(".js-periodo-promocao", context).on("click", function () {
        if ($(this).is(":checked")) {
            $(".js-data-promocao").removeClass("d-none");
        } else {
            $(".js-data-promocao").addClass("d-none");
        }
    });

    $(".js-preco-promocao", context).on("change", function () {
        if (parseFloat(this.value) > 0) {
            $(".js-duracao-promocao-assinatura", context).removeClass("d-none");
        } else {
            $(".js-duracao-promocao-assinatura", context).addClass("d-none");
        }
    });

    $(".js-gerenciar-estoque", context).on("click", function () {
        if ($(this).is(":checked")) {
            $(".js-status-estoque").addClass("d-none");
            $(".js-quantidade-estoque").removeClass("d-none");
        } else {
            $(".js-status-estoque").removeClass("d-none");
            $(".js-quantidade-estoque").addClass("d-none");
        }
    });

    $(".js-switch-yes-no", context).on("click", function () {
        let ativo = "Ativo";
        let inativo = "Inativo";
        if ($(this).data("ativo")) {
            ativo = $(this).data("ativo");
        }
        if ($(this).data("inativo")) {
            inativo = $(this).data("inativo");
        }

        if ($(this).is(":checked")) {
            $(this).closest(".custom-switch").find("label").html(ativo);
        } else {
            $(this).closest(".custom-switch").find("label").html(inativo);
        }
    });

    /**
     * Faixas de preços do produto
     */

    $(".js-seleciona-faixas-precos", context).on("click", function () {
        if ($(this).is(":checked")) {
            $(".js-precos-fixos").addClass("d-none");
            $(".js-faixas-precos").removeClass("d-none");
            $(".js-precos-fixos").addClass("js-form-obrigatorio");

            $(".js-descricao-faixa").attr("required", "required");
        } else {
            $(".js-precos-fixos").removeClass("d-none");
            $(".js-faixas-precos").addClass("d-none");
            $(".js-precos-fixos").removeClass("js-form-obrigatorio");

            $(".js-descricao-faixa").removeAttr("required");
        }
    });
    $(".js-add-faixa-preco", context).on("click", function () {
        const timestamp = new Date().getTime();
        const nova = parseInt($(".js-box-faixas .js-faixa").length) + timestamp;
        const modelo = $(".js-modelo-faixas").html();

        const replace = new RegExp("xxx", "g");
        const faixa = modelo.replace(replace, nova);

        $(".js-box-faixas").append(faixa);

        $(".js-remove-faixa", $(".js-box-faixas .js-faixa:last")).on(
            "click",
            function () {
                $(this).closest(".js-faixa").remove();
            }
        );
        Eloca.load($(".js-box-faixas .js-faixa:last"));
    });
    $(".js-remove-faixa", context).on("click", function () {
        $(this).closest(".js-faixa").remove();
    });

    $(".js-autocomplete-faixas", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/faixas-precos/busca"),
                minLength: 2,
                select: function (event, ui) {
                    const faixa = $(input).closest(".js-faixa");
                    $(".js-faixa-preco", faixa).val(
                        numeral(ui.item.preco).format("0,0.00")
                    );
                    $(".js-faixa-duracao", faixa).val(
                        numeral(ui.item.duracao).format("0")
                    );
                    setTimeout(function () {
                        $(".js-faixa-descricao", faixa).val(ui.item.descricao);
                    }, 1);
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            return $("<li>")
                .append(`${item.descricao} - R$ ${item.preco}`)
                .appendTo(ul);
        };
    });

    $(".js-seleciona-faixas-grupos", context).on("click", function () {
        console.log("change grupo");
        if ($(this).is(":checked")) {
            $(".js-faixas-grupos").removeClass("d-none");
            $(".js-descricao-grupo").attr("required", "required");
        } else {
            $(".js-faixas-grupos").addClass("d-none");
            $(".js-descricao-grupo").removeAttr("required");
        }
    });

    $(".js-create-faixa-grupo", context).on("click", function () {
        const routeStore = $(this).data("store");
        const produtoId = $("#js-produto-id", context).val();
        const descricao = $(".js-input-descricao-grupo", context).val();
        if (descricao.length < 3 || descricao.length > 50) {
            $(".js-input-descricao-grupo", context).addClass("is-invalid");
            return;
        }
        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: routeStore,
            type: "POST",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: { produto_id: produtoId, descricao },
            success: function (response) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (response.error) {
                    showToastDanger(context, response.message);
                } else {
                    $(".js-tabela-grupos", context).html(response.html);
                    $(".js-input-descricao-grupo", context).val("");
                    Eloca.load($(".js-tabela-grupos"));
                    showToastSuccess(context, "Grupo adicionado com sucesso");
                    atualizaSelectGrupos(response.itens, context);
                }
            },
            error: function (error) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    });

    $(".js-update-faixa-grupo", context).on("click", function () {
        const routeUpdate = $(this).data("update");
        const grupoId = $("#js-grupo-id", context).val();
        const descricao = $(".js-input-descricao-grupo", context).val();
        if (descricao.length < 3 || descricao.length > 50) {
            $(".js-input-descricao-grupo", context).addClass("is-invalid");
            return;
        }
        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: routeUpdate.replace("xxx", grupoId),
            type: "PUT",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: { descricao },
            success: function (response) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (response.error) {
                    showToastDanger(context, response.message);
                } else {
                    $(".js-tabela-grupos", context).html(response.html);
                    $(".js-input-descricao-grupo", context).val("");
                    $(".js-limpar-edicao-faixa-grupo", context).trigger(
                        "click"
                    );
                    Eloca.load($(".js-tabela-grupos"));
                    showToastSuccess(context, "Grupo adicionado com sucesso");
                    atualizaSelectGrupos(response.itens, context);
                }
            },
            error: function (error) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    });

    $(".js-delete-grupo-faixa", context).on("click", function () {
        const routeDelete = $(this).data("delete");

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: routeDelete,
            type: "DELETE",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (response) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (response.error) {
                    showToastDanger(context, response.message);
                } else {
                    $(".js-tabela-grupos").html(response.html);
                    Eloca.load($(".js-tabela-grupos"));
                    showToastSuccess(context, "Grupo deletado com sucesso");
                    atualizaSelectGrupos(response.itens, context);
                }
            },
            error: function (error) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    });

    $(".js-edit-grupo-faixas", context).on("click", function () {
        $("#js-grupo-id", $("#modalGrupos")).val($(this).data("id"));
        $(".js-input-descricao-grupo", $("#modalGrupos")).val(
            $(this).data("descricao")
        );
        $(".js-button-create", $("#modalGrupos")).addClass("d-none");
        $(".js-buttons-update", $("#modalGrupos")).removeClass("d-none");
        $(".js-input-descricao-grupo", $("#modalGrupos")).removeClass(
            "is-invalid"
        );
    });

    $(".js-limpar-edicao-faixa-grupo", context).on("click", function () {
        $("#js-grupo-id", $("#modalGrupos")).val("");
        $(".js-input-descricao-grupo", $("#modalGrupos")).val("");
        $(".js-button-create", $("#modalGrupos")).removeClass("d-none");
        $(".js-buttons-update", $("#modalGrupos")).addClass("d-none");
        $(".js-input-descricao-grupo", $("#modalGrupos")).removeClass(
            "is-invalid"
        );
    });

    /**
     * Informações Adicionais
     */
    $(".js-add-atributo", context).on("click", function () {
        const timestamp = new Date().getTime();
        const nova =
            parseInt($(".js-box-atributos .js-atributo").length) + timestamp;
        const modelo = $(".js-modelo-atributo").html();

        const replace = new RegExp("xxx", "g");
        const atributo = modelo.replace(replace, nova);

        $(".js-box-atributos").append(atributo);

        $(".js-remove-atributo", $(".js-box-atributos .js-atributo:last")).on(
            "click",
            function () {
                $(this).closest(".js-atributo").remove();
            }
        );
        Eloca.load($(".js-box-atributos .js-atributo:last"));
    });
    $(".js-remove-atributo", context).on("click", function () {
        $(this).closest(".js-atributo").remove();
    });

    $(".js-autocomplete-atributo", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/atributos/busca"),
                minLength: 2,
                select: function (event, ui) {
                    const atributo = $(input).closest(".js-atributo");
                    $(".js-atributo-valor", atributo).val(ui.item.valor);
                    setTimeout(function () {
                        $(".js-atributo-chave", atributo).val(ui.item.chave);
                    }, 1);
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            return $("<li>")
                .append(`${item.chave} - ${item.valor}`)
                .appendTo(ul);
        };
    });

    $(".js-autocomplete-create-produto-assinatura", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete"),
                minLength: 2,
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            const url = siteUrl(
                `/painel/assinaturas/produtosCreate/add/${item.id}`
            );
            let sku = "";
            if (item.sku && item.sku != null) {
                sku = ` - SKU: ${item.sku}`;
            }

            return $("<li class='lista-produto'>")
                .append(`<a href="${url}">#${item.id} - ${item.nome}${sku}<\a>`)
                .appendTo(ul);
        };
    });

    $(".js-autocomplete-edit-produto-assinatura", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete"),
                minLength: 2,
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            const url = siteUrl(
                `/painel/assinaturas/produtosEdit/add/${item.id}`
            );
            let sku = "";
            if (item.sku && item.sku != null) {
                sku = ` - SKU: ${item.sku}`;
            }

            return $("<li class='lista-produto'>")
                .append(`<a href="${url}">#${item.id} - ${item.nome}${sku}<\a>`)
                .appendTo(ul);
        };
    });

    $(".js-autocomplete-create-produto-pedidos", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete"),
                minLength: 2,
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            const url = siteUrl(
                `/painel/pedidos/produtosCreate/add/${item.id}`
            );
            let sku = "";
            if (item.sku && item.sku != null) {
                sku = ` - SKU: ${item.sku}`;
            }

            return $("<li class='lista-produto'>")
                .append(`<a href="${url}">#${item.id} - ${item.nome}${sku}<\a>`)
                .appendTo(ul);
        };
    });

    $(".js-autocomplete-edit-produto-pedidos", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete"),
                minLength: 2,
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            const url = siteUrl(`/painel/pedidos/produtosEdit/add/${item.id}`);
            let sku = "";
            if (item.sku && item.sku != null) {
                sku = ` - SKU: ${item.sku}`;
            }

            return $("<li class='lista-produto'>")
                .append(`<a href="${url}">#${item.id} - ${item.nome}${sku}<\a>`)
                .appendTo(ul);
        };
    });

    $(".js-autocomplete-produto", context).each(function () {
        const input = this;
        $(this).on("keyup", function () {
            if (this.value.length == 0) {
                $(input)
                    .closest(".form-group")
                    .find(".js-input-autocomplete")
                    .val("");
            }
        });
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete?status=ativos"),
                minLength: 2,
                select: function (event, ui) {
                    setTimeout(function () {
                        $(input)
                            .closest(".form-group")
                            .find(".js-input-autocomplete")
                            .val(ui.item.id);
                        $(input)
                            .closest(".form-group")
                            .find(".js-preco-produto")
                            .val(ui.item.precos_str);
                        $(input).val(ui.item.nome);
                    }, 1);
                    /* const atributo = $(input).closest(".js-atributo");
                    $(".js-atributo-valor", atributo).val(ui.item.valor);
                    setTimeout(function () {
                        $(".js-atributo-chave", atributo).val(ui.item.chave);
                    }, 1); */
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            $(ul).css("z-index", 9999);
            let sku = "";
            if (item.sku && item.sku != null) {
                sku = ` - SKU: ${item.sku}`;
            }

            return $("<li>")
                .append(`#${item.id} - ${item.nome} ${sku}`)
                .appendTo(ul);
        };
    });

    /**
     * Imagens do produto
     */

    $(".js-add-imagem", context).on("click", function () {
        const timestamp = new Date().getTime();
        const nova =
            parseInt($(".js-box-imagens .js-imagem").length) + timestamp;
        const modelo = $(".js-modelo-imagem").html();

        const replace = new RegExp("xxx", "g");
        const imagem = modelo.replace(replace, nova);

        $(".js-box-imagens").append(imagem);

        $(".js-remove-imagem", $(".js-box-imagens .js-imagem:last")).on(
            "click",
            function () {
                $(this).closest(".js-imagem").remove();
            }
        );
        Eloca.load($(".js-box-imagens .js-imagem:last"));
    });
    $(".js-remove-imagem", context).on("click", function () {
        $(this).closest(".js-imagem").remove();
    });

    /**
     * Acessórios do produto
     */

    $(".js-add-acessorio", context).on("click", function () {
        const timestamp = new Date().getTime();
        const nova =
            parseInt($(".js-box-acessorios .js-acessorio").length) + timestamp;
        const modelo = $(".js-modelo-acessorio").html();

        const replace = new RegExp("xxx", "g");
        const acessorio = modelo.replace(replace, nova);

        $(".js-box-acessorios").append(acessorio);

        $(
            ".js-remove-acessorio",
            $(".js-box-acessorios .js-acessorio:last")
        ).on("click", function () {
            $(this).closest(".js-acessorio").remove();
        });
        Eloca.load($(".js-box-acessorios .js-acessorio:last"));
    });
    $(".js-remove-acessorio", context).on("click", function () {
        $(this).closest(".js-acessorio").remove();
    });

    function atualizaSelectGrupos(itens) {
        console.log("atualiza", $(".js-select-grupos-faixas"));
        $(".js-select-grupos-faixas").each(function (id, select) {
            const selecionado = $(select).val();
            $(select).html("<option value=''>Selecione</option>");
            console.log("limpa tudo");
            $(itens).each(function (i, item) {
                if (item.id == selecionado) {
                    $(select).append(
                        `<option value="${item.id}" selected>${item.value}</option>`
                    );
                } else {
                    $(select).append(
                        `<option value="${item.id}">${item.value}</option>`
                    );
                }
            });
        });
    }
};
